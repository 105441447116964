// SemanticUI-free pre-@plone/components
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import config from '@plone/volto/registry';
import { UniversalLink } from '@plone/volto/components';
import { toBackendLang } from '@plone/volto/helpers';
import LogoImage from './Logo.svg';
//import LogoImage from './E_symbol.jpg';

const messages = defineMessages({
  site: {
    id: 'Site',
    defaultMessage: 'Site',
  },
  plonesite: {
    id: 'Plone Site',
    defaultMessage: 'Plone Site',
  },
});

const Logo = () => {
  const { settings } = config;
  const lang = useSelector((state) => state.intl.locale);
  const intl = useIntl();

  return (
    <UniversalLink href={settings.isMultilingual ? `/${toBackendLang(lang)}` : '/'} title={intl.formatMessage(messages.site)}>
      <img src={LogoImage} alt={intl.formatMessage(messages.plonesite)} title={intl.formatMessage(messages.plonesite)} />
    </UniversalLink>
  );
};

export default Logo;
