// SemanticUI-free pre-@plone/components
import React from 'react';

import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { UniversalLink, Logo } from '@plone/volto/components';
import { Container } from '@plone/components';
import { flattenToAppURL, addAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { useGoogleAnalytics } from 'volto-google-analytics';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const { settings } = config;
  const { lang, siteActions = [] } = useSelector(
    (state) => ({
      lang: state.intl.locale,
      siteActions: state.actions?.actions?.site_actions,
    }),
    shallowEqual,
  );

  useGoogleAnalytics();

  return (
    <footer id="footer">
      <Container layout className="footer">
        <ul>
          {/* wrap in div for a11y reasons: listitem role cannot be on the <a> element directly */}
          {siteActions?.length
            ? siteActions.map((item) => (
                <li className="item" key={item.id}>
                  <UniversalLink className="item" href={settings.isMultilingual ? `/${lang}/${item.url ? flattenToAppURL(item.url) : addAppURL(item.id)}` : item.url ? flattenToAppURL(item.url) : addAppURL(item.id)}>
                    {item?.title}
                  </UniversalLink>
                </li>
              ))
            : null}
        </ul>
      </Container>
      <Container layout className="footer social-footer">
        <a className="social social-yt" href="https://www.youtube.com/channel/UCcL6yiaqQNPc7Z9kELWFIqQ" title="ECHAMP on YouTube">
          {' '}
        </a>
        <a className="social social-li" href="https://www.linkedin.com/company/echamp" title="ECHAMP on LinkedIn">
          {' '}
        </a>
        <a className="social social-tw" href="https://twitter.com/ECHAMPeu" title="ECHAMP on Twitter">
          {' '}
        </a>
      </Container>
      <Container layout className="footer">
        <p className="echamp-footer-text">ECHAMP works to develop the industry for homeopathic and anthroposophic medicinal products so as to ensure availability of both medicines for self-medication and medicines recommended by prescribers.</p>
      </Container>
    </footer>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
