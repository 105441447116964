import React from 'react';
import { DefaultView } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { Container, Segment, Grid, GridColumn, Label } from 'semantic-ui-react';

import { flattenHTMLToAppURL } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';

import Image from '@plone/volto/components/theme/Image/Image';

const OrganisationView = (props) => {
  const { content } = props;
  const hasLogo = content?.logo ? true : false;

  return (
    <Container className="view-wrapper OrganisationView">
      <Grid stackable columns={2}>
        <Grid.Row>
          <GridColumn>
            {' '}
            <h1 className="documentFirstHeading">{content.title}</h1>
          </GridColumn>
          <GridColumn right floated>
            {hasLogo && <Image item={content} imageField={'logo'} alt="Organisation Logo" title="Organisation Logo" className="organisation-logo" />}
          </GridColumn>
        </Grid.Row>
        <Grid.Row>
          {content.text && (
            <span
              dangerouslySetInnerHTML={{
                __html: content.text.data,
              }}
            />
          )}
        </Grid.Row>
        {content.address && (
          <Grid.Row>
            <span
              dangerouslySetInnerHTML={{
                __html: content.address,
              }}
            />
          </Grid.Row>
        )}
        {content.phone && (
          <Grid.Row>
            <span
              dangerouslySetInnerHTML={{
                __html: content.phone,
              }}
            />
          </Grid.Row>
        )}
        {content.email && (
          <Grid.Row>
            <UniversalLink href={content.email} title={content.email}>
              {content.email}
            </UniversalLink>
          </Grid.Row>
        )}
        {content.web && (
          <Grid.Row>
            <UniversalLink href={content.web} title={content.web}>
              {content.web}
            </UniversalLink>
          </Grid.Row>
        )}
      </Grid>
    </Container>
  );
};

export default OrganisationView;
