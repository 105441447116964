import config from '@plone/volto/registry';
import { defineMessages } from 'react-intl';
import { addStyling } from '@plone/volto/helpers/Extensions/withBlockSchemaEnhancer';

const messages = defineMessages({
  innerBackgroundColor: {
    id: 'Inner background color',
    defaultMessage: 'Inner background color',
  },
});

export const innerBGColorSchemaEnhancer = ({ schema, formData, intl }) => {
  const INNER_BG_COLORS = [
    { name: 'transparent', label: 'Transparent' },
    { name: 'grey', label: 'Grey' },
    { name: 'news', label: 'News / Events' },
    { name: 'sector', label: 'Sector' },
    { name: 'policy', label: 'Policy / Legislation' },
    { name: 'resources', label: 'Resources' },
    { name: 'inside', label: 'Inside' },
  ];

  addStyling({ schema, intl });

  const colors = config.blocks?.blocksConfig?.[formData['@type']]?.innerColors || INNER_BG_COLORS;

  const defaultInnerBGColor = config.blocks?.blocksConfig?.[formData['@type']]?.defaultInnerBGColor || 'transparent';

  schema.properties.styles.schema.fieldsets[0].fields = [...schema.properties.styles.schema.fieldsets[0].fields, 'innerBackgroundColor'];
  schema.properties.styles.schema.properties.innerBackgroundColor = {
    widget: 'color_picker',
    title: intl.formatMessage(messages.innerBackgroundColor),
    colors,
    default: defaultInnerBGColor,
  };

  return schema;
};
