import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { ConditionalLink, UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  default_title_related_items: {
    id: 'default_title_related_items',
    defaultMessage: 'Related items',
  },
});

const RelatedItems = (props) => {
  const { items } = props;
  const intl = useIntl();

  let itemfiltered = [];
  if (items?.length > 0) {
    itemfiltered = items.filter((item) => {
      return item !== null;
    });
  }

  const title = intl.formatMessage(messages.default_title_related_items);

  return itemfiltered.length > 0 ? (
    <div className="q container view-wrapper related-items">
      <h4>{title}</h4>
      <ul className="related-items-list">
        {itemfiltered.map((item) => (
          <li key={item['@id']}>
            <UniversalLink href={flattenToAppURL(item['@id'])}>{title ? item.title : item.id}</UniversalLink>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

RelatedItems.propTypes = {
  props: PropTypes.arrayOf(PropTypes.any),
};

export default RelatedItems;
