import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  Copyright: {
    id: 'Copyright/Source',
    defaultMessage: 'Copyright/Source',
  },
});

export const imageBlockSchemaEnhancer = ({ formData, schema, intl }) => {
  if (formData.url) {
    schema.fieldsets[0].fields = ['alt', 'description', 'copyright_and_sources', 'align', 'size'];
    schema.properties.description = {
      title: intl.formatMessage(messages.Description),
    };
    schema.properties.copyright_and_sources = {
      title: intl.formatMessage(messages.Copyright),
    };
  }
  schema.properties.align.default = 'center';
  schema.properties.align.actions = ['left', 'right', 'center', 'wide', 'full'];
  schema.properties.size.default = 'l';
  schema.properties.size.disabled = formData.align === 'full' || formData.align === 'wide' || formData.align === 'center';

  return schema;
};
