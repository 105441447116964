import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { FormattedDate } from '@plone/volto/components';
import { Icon } from '@plone/volto/components';

import pdfSVG from '../../../icons/pdf.svg';

const DefaultTemplate = ({ headlineTag, items, linkTitle, linkHref, isEditMode }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <UniversalLink href={href}>{linkTitle || href}</UniversalLink>;
  }

  const getTitleTag = (tag) => {
    const level = tag.slice(-1);
    if (/\d/.test(level)) {
      return `h${Number(level) + 1}`;
    } else {
      return 'h3';
    }
  };
  const TitleTag = headlineTag ? getTitleTag(headlineTag) : 'h3';

  const format = {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
  };
  return (
    <>
      <div className="items">
        <table className="ui celled table">
          <tbody>
            {items.map((item) => (
              <tr className="listing-table-item" key={item['@id']}>
                <td className="listing-table-title">
                  <ConditionalLink item={item} condition={!isEditMode}>
                    {' '}
                    {item.mime_type === 'application/pdf' ? <Icon name={pdfSVG} size="24px" fill="black" /> : ''} <b>{item.title ? item.title : item.id}</b>{' '}
                  </ConditionalLink>
                </td>
                <td>{item.description}</td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {' '}
                  {item.mime_type?.split('/')[1]?.toUpperCase()}&nbsp;({item?.getObjSize?.toUpperCase()})
                </td>

                <td className="listing-table-date"> {item.effective && <FormattedDate date={item.effective} format={format} />}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};
DefaultTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default DefaultTemplate;
