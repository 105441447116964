// Blocks
import DefaultListingBlockTemplate from '@plone/volto/components/manage/Blocks/Listing/DefaultTemplate';
import SummaryListingBlockTemplate from '@plone/volto/components/manage/Blocks/Listing/SummaryTemplate';
import ImageListingTemplate from '@plone/volto/components/manage/Blocks/Listing/ImageGallery';
import DefaultWithDateListing from './components/Blocks/ListingTemplates/DefaultWithDateListing';
import TableWithDateListing from './components/Blocks/ListingTemplates/TableWithDateListing';
import TableFileListing from './components/Blocks/ListingTemplates/TableFileListing';

import GridListingBlockTemplate from '@kitconcept/volto-light-theme//components/Blocks/Listing/GridTemplate';

import { OrganisationView } from './components';

import { addStyling } from '@plone/volto/helpers/Extensions/withBlockSchemaEnhancer';

import { imageBlockSchemaEnhancer } from './components/Blocks/Image/schema';
import { ImageBlockDataAdapter } from './components/Blocks/Image/adapter';
import { composeSchema } from '@plone/volto/helpers';
import { defineMessages } from 'react-intl';
import { gridImageDisableSizeAndPositionHandlersSchema } from '@plone/volto/components/manage/Blocks/Image/schema';

import { defaultStylingSchema, removeStylingSchema } from '@kitconcept/volto-light-theme/components/Blocks/schema';

import { innerBGColorSchemaEnhancer } from './components/Blocks/innerBGColorSchemaEnhancer';
import { teaserSchemaEnhancer } from '@kitconcept/volto-light-theme/components/Blocks/Teaser/schema';

import RelatedItemsView from './components/Blocks/RelatedItems/View';
import RelatedItemsEdit from './components/Blocks/RelatedItems/Edit';
import moreSVG from '@plone/volto/icons/more.svg';

// Icons
import sliderSVG from '@plone/volto/icons/slider.svg';

const BG_COLORS = [
  { name: 'transparent', label: 'White / Green' },
  { name: 'news', label: 'News / Events' },
  { name: 'sector', label: 'Sector' },
  { name: 'policy', label: 'Policy / Legislation' },
  { name: 'resources', label: 'Resources' },
  { name: 'inside', label: 'Inside' },
];

const applyConfig = (config) => {
  config.blocks.requiredBlocks = [];
  config.settings = {
    ...config.settings,
    isMultilingual: false,
    supportedLanguages: ['en'],
    defaultLanguage: 'en',
    image_crop_aspect_ratios: [
      {
        label: '16:9',
        ratio: 16 / 9,
      },
      {
        label: '4:3',
        ratio: 4 / 3,
      },
      {
        label: '1:1',
        ratio: 1,
      },
    ],
    socialNetworks: [
      {
        id: 'twitter',
        href: 'https://twitter.com/ECHAMPeu',
      },
      {
        id: 'linkedin',
        href: 'https://www.linkedin.com/company/echamp',
      },
      {
        id: 'youtube',
        href: 'https://www.youtube.com/channel/UCcL6yiaqQNPc7Z9kELWFIqQ',
      },
    ],
  };
  // Blocks

  config.experimental.addBlockButton.enabled = true;
  config.settings.showSelfRegistration = false;

  // light theme fat menu
  config.settings.navDepth = 3;
  config.settings.enableFatMenu = true;

  config.settings.imageObjects = {
    ...config.settings.imageObjects,
    'echamp.Organisation': 'logo',
  };

  config.blocks.blocksConfig.listing.related_items = {
    id: 'related_items',
    title: 'Related items',
    icon: moreSVG,
    group: 'common',
    view: RelatedItemsView,
    edit: RelatedItemsEdit,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: false,
    security: {
      addPermission: [],
      view: [],
    },
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    allowed_headline_tags: [['h2', 'h2']],
    colors: BG_COLORS,
    variations: [
      {
        id: 'default',
        title: 'Standard',
        isDefault: true,
        template: DefaultListingBlockTemplate,
      },
      {
        id: 'default_dates',
        title: 'Standard (With Dates)',
        isDefault: false,
        template: DefaultWithDateListing,
      },
      {
        id: 'table_dates',
        title: 'Table (With Dates)',
        isDefault: false,
        template: TableWithDateListing,
      },
      {
        id: 'files',
        title: 'Table for Files',
        isDefault: false,
        template: TableFileListing,
      },
      {
        id: 'summary',
        title: 'Standard (with Image)',
        template: SummaryListingBlockTemplate,
      },
      {
        id: 'imageListing',
        title: 'Image Gallery',
        template: ImageListingTemplate,
      },
      {
        id: 'grid',
        title: 'Grid',
        template: GridListingBlockTemplate,
      },
    ],
  };

  config.blocks.blocksConfig.image = {
    ...config.blocks.blocksConfig.image,
    schemaEnhancer: imageBlockSchemaEnhancer,
    dataAdapter: ImageBlockDataAdapter,
  };

  config.blocks.blocksConfig.introduction = {
    ...config.blocks.blocksConfig.introduction,
    schemaEnhancer: composeSchema(defaultStylingSchema, innerBGColorSchemaEnhancer),
    colors: BG_COLORS,
  };

  config.blocks.blocksConfig.separator = {
    ...config.blocks.blocksConfig.separator,
    colors: BG_COLORS,
  };

  config.blocks.blocksConfig.heading = {
    ...config.blocks.blocksConfig.heading,
    colors: BG_COLORS,
    schemaEnhancer: composeSchema(defaultStylingSchema, innerBGColorSchemaEnhancer),
  };

  config.blocks.blocksConfig.slate = {
    ...config.blocks.blocksConfig.slate,
    colors: BG_COLORS,
    schemaEnhancer: composeSchema(defaultStylingSchema, innerBGColorSchemaEnhancer),
    sidebarTab: 0,
  };

  config.blocks.blocksConfig.gridBlock = {
    ...config.blocks.blocksConfig.gridBlock,
    allowedBlocks: ['teaser', 'image', 'slate', 'listing', 'introduction', 'heading'],
    blocksConfig: {
      ...config.blocks.blocksConfig.gridBlock.blocksConfig,
      image: {
        ...config.blocks.blocksConfig.image,
        schemaEnhancer: composeSchema(imageBlockSchemaEnhancer, gridImageDisableSizeAndPositionHandlersSchema),
      },
      // slate: {
      //   ...config.blocks.blocksConfig.slate,
      //   colors: BG_COLORS,
      //   schemaEnhancer: composeSchema(defaultStylingSchema, innerBGColorSchemaEnhancer),
      //   sidebarTab: 0,
      // },
      listing: {
        ...config.blocks.blocksConfig.listing,
      },
    },
    colors: BG_COLORS,
  };

  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      'echamp.Organisation': OrganisationView,
    },
  };

  // console.debug('** config.views', config.views);
  // console.debug('** config.settings', config.settings);

  return config;
};

export default applyConfig;
